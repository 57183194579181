import { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Card, CardContent, Typography } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";

export default function Finances() {
  const [documents, setDocuments] = useState([
    { title: "Articles of Incorporation", link: process.env.PUBLIC_URL + "/uploads/finances/incorporation.jpg" },
    { title: "Determination Letter", link: process.env.PUBLIC_URL + "/uploads/finances/determination.pdf" },
    { title: "990n 2021", link: process.env.PUBLIC_URL + "/uploads/finances/990n_2021.pdf" },
  ]);
  return (
    <>
      <Helmet>
        <title>Financial Transparency | Warriors Keep</title>
      </Helmet>
      <div
        className="about_container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="finances_title">
          <Typography variant="h3" gutterBottom component="div">
            Public Financial Documentation
          </Typography>
        </div>
        <div
          className="finances_container"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexWrap: "wrap",
          }}
        >
          {documents.map((document) => (
            <Card
              className="finances_card"
              style={{
                minWidth: "350px",
                margin: "10px",
              }}
            >
              <CardContent>
                <ArticleIcon className="finances_icon" />
                <Typography variant="h5" component="h2">
                  {document.title}
                </Typography>
                <Typography variant="body2" component="p">
                  <a href={document.link}>Click here to view</a>
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
}
