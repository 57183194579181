import React from "react";
import "./MissionInfo.css";

const MissionInfo = () => {
  return (
    <div className="mission_container">
      <div className="mission_info">
        <h1>Warriors Keep</h1>
      </div>
      <hr style={{ width: "100%" }} />

      <div className="mission_story">
        <div className="mission_text">
          <h2>Mission</h2>
          <li>
            <p>Warriors Keep is a faith-based non-profit organization that trains service dogs for veterans with PTSD. Veterans and family members will also gain the skills needed to train their own service dog as well as experience physical, mental and spiritual opportunities to help restore their lives.</p>
          </li>
          <h2>Vision</h2>
          <li>
            <p>Warriors Keep will be a community-based program with a tight knit group of volunteers that help to fulfill the spiritual and mental health needs of veterans and their families. Warriors Keep will become a fellowship that offers activities and growth opportunities. The program will meet on a regular basis to provide both training events as well as spiritual and social activities.</p>
          </li>
        </div>
        <img src={process.env.PUBLIC_URL + "/logo_flag.png"} alt="logo" />
      </div>
    </div>
  );
};

export default MissionInfo;
