import React from "react";
import Xarrow from "react-xarrows";
import { Link } from "react-router-dom";
import "./Timeline.css";

const Timeline = () => {
  return (
    <div className="timeline_container">
      <div className="timeline_row">
        <div className="timeline_col1">
          <div className="timeline_box" id="xarrowBox" style={{ width: "90%" }}>
            <h2>What we do</h2>
            <p>
              <i>
                Warriors keep offers a 12 to 18 month program where Veterans can learn to train their own Service Dog. The program is broken into multiple
                phases including instruction, integration, and public access with a Service Dog.{" "}
              </i>
              <i>Read more about&nbsp;</i>
              <Link to="/about/mission" style={{ textDecoration: "none" }}>
                <u>our Mission and Vision.</u>
              </Link>
            </p>
          </div>
        </div>
        <div className="vl" id="xarrowLine"></div>
        <div className="timeline_col2">
          <img id="xarrowImg" src={process.env.PUBLIC_URL + "/uploads/mission.webp"} alt="Service dog" />
        </div>
      </div>

      <div className="timeline_row">
        <div className="timeline_col2">
          <img id="xarrowImg2" src={process.env.PUBLIC_URL + "/uploads/application.webp"} alt="Service dog" />
        </div>
        <div className="timeline_col1">
          <div className="timeline_box" id="xarrowBox2">
            <h2>Application</h2>
            <p>
              <i>Application for our programs is&nbsp;</i>
              <Link to="/contact" style={{ textDecoration: "none" }}>
                <u>available here.</u>
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="xarrows">
        <Xarrow
          lineColor="rgb(94, 102, 110)"
          headSize={0}
          strokeWidth={2}
          path="grid"
          startAnchor="right"
          endAnchor="bottom"
          start="xarrowBox"
          end="xarrowLine"
        />
        <Xarrow lineColor="rgb(94, 102, 110)" headSize={0} startAnchor="bottom" strokeWidth={2} path="grid" start="xarrowLine" end="xarrowImg" />
        <Xarrow
          lineColor="rgb(94, 102, 110)"
          headSize={0}
          strokeWidth={2}
          path="grid"
          startAnchor="left"
          endAnchor="bottom"
          start="xarrowBox2"
          end="xarrowLine"
        />
        <Xarrow lineColor="rgb(94, 102, 110)" headSize={0} startAnchor="bottom" strokeWidth={2} path="grid" start="xarrowLine" end="xarrowImg2" />
      </div>
    </div>
  );
};

export default Timeline;
