import React from "react";
import { Link } from "react-router-dom";
import "./TransparencyPlead.css";

const TransparencyPlead = () => {
  return (
    <>
      <div className="transparency_container">
        <div>
          <h3>Financial Transparency</h3>
          <p>Our expenses support our mission of eliminating veteran suicide through dynamic, community-led initiatives.</p>
        </div>
        <div>
          <img alt="finances" src={process.env.PUBLIC_URL + "/finances.webp"} />
        </div>
        <div>
          <p>Visit our public documentation page</p>
          <Link to="/about/finances">
            <button>Go to Documentation</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default TransparencyPlead;
