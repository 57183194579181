import React, { useState, useEffect } from "react";
import emailjs, { init } from "emailjs-com";
import axios from "axios";
import "./SendMessage.css";
init(process.env.REACT_APP_EMAILJS_USER_ID);

const ReCAPTCHA = React.lazy(() => import('react-google-recaptcha'));

const SendMessage = () => {
  const [captchaPassed, setCaptchaPassed] = useState(false);
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  function onFormSubmit() {
    var templateParams = {
      subject: subject,
      email: senderEmail,
      from_name: senderName,
      message: message,
      phone_number: phoneNumber,
    };
    if (senderName.length === 0 || senderEmail.length === 0 || message.length === 0) {
      alert("Not all required fields are filled.");
      return;
    }

    axios
      .post(process.env.REACT_APP_PUBLIC_URL + "/send_message", templateParams)
      .then((response) => {
        alert("Message has been sent sucessfully!");
      })
      .catch((error) => {
        alert("Message could not be sent sucessfully.");
      });
  }

  return (
    <div className="send_message_container">
      <div className="send_message_col">
        <img alt="German shepherd running with stick" src={process.env.PUBLIC_URL + "uploads/contact_form.webp"} />
        <h3>Warriors Keep</h3>
        <p>info@warriorskeep.faith</p>
      </div>
      <div className="send_message_col">
        <form
          id="send_message"
          // action="" method="post"
          onSubmit={(event) => {
            event.preventDefault();
            onFormSubmit();
          }}
          noValidate
        >
          <h3>Send us a message!</h3>
          <input
            onChange={(event) => {
              setSenderName(event.target.value);
            }}
            placeholder="Your Name"
            type="text"
            tabIndex="1"
            required
          />
          <input
            onChange={(event) => {
              setSenderEmail(event.target.value);
            }}
            placeholder="Your Email Address"
            type="email"
            tabIndex="2"
            required
          />
          <input
            onChange={(event) => {
              setPhoneNumber(event.target.value);
            }}
            placeholder="Your Phone Number (Optional)"
            type="tel"
            tabIndex="3"
          />
          <input
            onChange={(event) => {
              setSubject(event.target.value);
            }}
            placeholder="Subject"
            type="subject"
            tabIndex="4"
            required
          />
          <textarea
            onChange={(event) => {
              setMessage(event.target.value);
            }}
            placeholder="Type your Message body here."
            tabIndex="5"
            required
          ></textarea>
          <React.Suspense fallback={<div>Loading...</div>}>
          <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={() => setCaptchaPassed(true)} />
          </React.Suspense>
          <button
            disabled={captchaPassed ? false : true}
            name="submit"
            type={captchaPassed ? "submit" : "submit-disabled"}
            id="contact-submit"
            data-submit="Sending"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default SendMessage;
