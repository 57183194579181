import React from "react";
import { Link } from "react-router-dom";
import "./Facts.css";

const Facts = () => {
  return (
    <>
      <div className="facts_container">
        <div>
          <img src={process.env.PUBLIC_URL + "/uploads/fact1.webp"} alt="Mental health challenge" />
        </div>
        <div>
          <img src={process.env.PUBLIC_URL + "/uploads/fact2.webp"} alt="Mental health challenge" />
        </div>
        <div>
          <img src={process.env.PUBLIC_URL + "/uploads/fact3.webp"} alt="Mental health challenge" />
        </div>
      </div>
    </>
  );
};

export default Facts;
