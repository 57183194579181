import "./App.css";
import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header.jsx";
import Home from "./containers/home/Home.jsx";
import ScrollToTop from "./utility/ScrollToTop";
import ScrollToTopButton from "./utility/ScrollToTopButton";
import Faq from "./containers/about/faq/Faq";
import Mission from "./containers/about/mission/Mission";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { Helmet } from "react-helmet";
import Finances from "./containers/about/finances/Finances";

const Contact = lazy(() => import("./containers/contact/Contact"));
const Donation = lazy(() => import("./containers/donation/Donation"));
const Projects = lazy(() => import("./containers/projects/Projects"));
const Project = lazy(() => import("./containers/project/Project"));
const Resources = lazy(() => import("./containers/resources/Resources"));

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const theme = createTheme({
  palette: {
    primary: {
      main: "#202c54",
      light: "#FEFAF1",
    },
    secondary: {
      main: "#eaa23b",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {},
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Warriors Keep - Service Dogs, Veteran Support & Training | Faith-Based Arkansas Non-Profit</title>
        <meta name="description" content="Warriors Keep is a faith-based non-profit organization based in Arkansas that trains service dogs for veterans with PTSD. Veterans and family members will also gain the skills needed to train their own service dog as well as experience physical, mental and spiritual opportunities to help restore their lives." />
      </Helmet>
      <Router>
        <Header />
        <ScrollToTopButton />
        <ScrollToTop />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/resources" element={<Resources />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/projects/:id" element={<Project />} />
            <Route path="/donate" element={<Donation />} />
            <Route path="/about/faq" element={<Faq />} />
            <Route path="/about/mission" element={<Mission />} />
            <Route path="/about/finances" element={<Finances />} />
          </Routes>
        </Suspense>

        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
