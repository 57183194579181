import React from "react";
import FaqQuestion from "../../../components/faqQuestion/FaqQuestion";
import "./Faq.css";

const Faq = () => {
  return (
    <div className="faq_container">
      {faqs.map((faq, index) => (
        <FaqQuestion key={index} faq={faq} />
      ))}
    </div>
  );
};

const faqs = [
  {
    question: "What is a service animal?",
    paragraph:
      "Under the ADA, a service animal is defined as a dog that has been individually trained to do work or perform tasks for an individual with a disability.  The task(s) performed by the dog must be directly related to the person's disability.",
  },
  {
    question: `What does "do work or perform tasks" mean?`,
    paragraph:
      "The dog must be trained to take a specific action when needed to assist the person with a disability. For example, a person with diabetes may have a dog that is trained to alert him when his blood sugar reaches high or low levels. A person with depression may have a dog that is trained to remind her to take her medication. Or, a person who has epilepsy may have a dog that is trained to detect the onset of a seizure and then help the person remain safe during the seizure.",
  },
  {
    question: "What questions can a covered entity's employees ask to determine if a dog is a service animal?",
    paragraph:
      "Staff are not allowed to request any documentation for the dog, require that the dog demonstrate its task, or inquire about the nature of the person's disability. In situations where it is not obvious that the dog is a service animal, staff may ask only two specific questions:",
    list: ["Is the dog a service animal required because of a disability?", "What work or task has the dog been trained to perform?"],
  },
  // {
  //   question: "What exactly does Warriors Keep do second question?",
  //   paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce porta sapien sit amet arcu aliquet sollicitudin. Nulla commodo tristique lacinia. Nam non euismod lectus, vel egestas nisi. Nunc convallis, metus in fringilla placerat, neque lacus volutpat nisi, at gravida turpis velit at dui. Our work includes:",
  //   list: ["Helping traumatized warriors reintegrate into society."],
  //   linkList: [{ title: "Our financial reports 2020", link: "https://www.google.com/" }],
  // },
];

export default Faq;
