import React from "react";
import CustomCarousel from "../../components/customCarousel/CustomCarousel.js";
import Timeline from "../../components/timeline/Timeline.js";
import TransparencyPlead from "../../components/transparencyPlead/TransparencyPlead";
import Facts from "../../components/facts/Facts";
import SendMessage from "../../components/sendMessage/SendMessage";

export default function Home() {
  return (
    <>
      <CustomCarousel />
      <Timeline />
      <SendMessage />
      <TransparencyPlead />
      <Facts />
    </>
  );
}
