import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import "./CustomCarousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CustomCarousel = () => {
  return (
    <Carousel
      infiniteLoop={true}
      showStatus={false}
      className="carousel_container"
      showThumbs={false}
      showArrows={true}
      useKeyboardArrows={true}
      autoPlay={true}
      interval={5000}
      swipeable={true}
      verticalSwipe="standard"
    >
      <div>
        <img alt="Slide picture of dogs with American flag background" src={process.env.PUBLIC_URL + "uploads/veterans_slide.webp"} />
        <div className="slideContent">
          <h1>
            Fellowship of <u>warriors.</u>
          </h1>
          <Link to="/contact">
            <button className="carousel_button1">Apply Here</button>
          </Link>
        </div>
      </div>
      <div>
        <img alt="Slide picture of dog" src={process.env.PUBLIC_URL + "uploads/veterans_slide2.webp"} />
        <div className="slideContent">
          <Link
            to="/projects/train"
            style={{
              textDecoration: "none",
              color: "white",
            }}
          >
            <h2>
              <u>service dog</u> training program
            </h2>
            <button className="carousel_button2">See more</button>
          </Link>
        </div>
      </div>
    </Carousel>
  );
};

export default CustomCarousel;
